<template>
  <main class="history">
    <b-breadcrumb :items="breadcrumb" />
    <div class="container" :class="{ 'mx-auto': !sidebarShow }">
      <section class="section">
        <div class="section-title history-title">Account Change Log</div>
        <div class="section-table history-table">
          <div class="section-table-content" v-if="historyShow">
            <CDataTable
              class="history-dataTable"
              :items="historyData"
              :fields="fields"
              :table-filter="{
                label: 'Search:',
                placeholder: ''
              }"
              :pagination="{ align: 'center' }"
              :itemsPerPageSelect="{ values: [10, 20, 50, 100] }"
              hover
              :sorter="{ resetable: true }"
            >
              <template #sorting-icon="{state}">
                <TableSortingIcon :state="state"></TableSortingIcon>
              </template>
            </CDataTable>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { CDataTable } from '@coreui/vue/src'
import TableSortingIcon from '../components/TableSortingIcon.vue'

export default {
  name: 'History',
  components: { CDataTable, TableSortingIcon },
  data() {
    return {
      breadcrumb: [
        {
          text: 'Account Change Log',
          active: true
        }
      ],
      historyShow: false,
      fields: [
        { key: 'action', label: 'Action', _style: 'min-width:100px;' },
        { key: 'attribute', label: 'Attribute', _style: 'min-width:150px;' },
        { key: 'entity', label: 'Entity', _style: 'min-width:100px;' },
        { key: 'time', label: 'Time', _style: 'min-width:200px;' },
        { key: 'user', label: 'User', _style: 'min-width:150px;' }
      ]
    }
  },
  async mounted() {
    let status = await this['history/pageHomeCreated']()
    if (status === 'success') {
      this.historyShow = true
    } else {
      console.log('history created: ' + status)
      this.$router.push('/pages/page403')
    }
  },
  computed: {
    ...mapState({
      sidebarShow: state => state.sidebarShow,
      history: state => state.history.list
    }),
    historyData() {
      if (!this.history) return []
      return this.history.map(item => {
        let time = this.dateToString(item.time)
        return {
          action: item.action,
          attribute: item.attribute,
          entity: item.entity,
          time,
          user: `${item.user.firstName} ${item.user.lastName}`
        }
      })
    }
  },
  methods: {
    ...mapActions(['history/pageHomeCreated']),
    dateToString(time) {
      return new Date(time).toLocaleString()
    }
  }
}
</script>
